import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";

function AccountEdit(props) {
    const { userRole, userId, checkLoggedIn } = useContext(AuthContext);

    const [saving, setSaving] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [isNew, setIsNew] = useState(null);
    const [roles, setRoles] = useState([]);
    const [defaultRole, setDefaultRole] = useState("");
    const [defaultCompany, setDefaultCompany] = useState("");
    const [companies, setCompanies] = useState([]);
    const [confirming, setConfirming] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();

    function nameClassDisabled(name) {
        return (name === undefined || ['admin', 'dummy'].includes(name.toLowerCase())) ? ' disabled ' : '';
    }

    function isNameDisabled(name) {
        return nameClassDisabled(name) === ' disabled ';
    }

    function loadRolesData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/roles";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setRoles(res.data.roles);
                if (userRole.name.toLowerCase() === "admin") {
                    let default_role_id = res.data.roles.filter((r) => r.name.toLowerCase() === "manager")[0]._id;
                    setDefaultRole(default_role_id)
                    console.log(default_role_id);
                } else {
                    let default_role_id = res.data.roles.filter((r) => r.name.toLowerCase() === "agent")[0]._id;
                    setDefaultRole(default_role_id)
                }
                return res.data.roles;
            }
            return [];
        });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);

        saveUser()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn().then((res) => {
                    if (res) {
                        const my_uid = res;
                        axios.get(ENDPOINT, {
                            params: { id: my_uid },
                            withCredentials: true,
                        })
                            .then((l_res) => {
                                const userIsAdmin = l_res.data.user.role.access.manage_child_users;
                                props.reLoad(userIsAdmin);
                            })
                    }
                })
            })

    }

    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setUserId("");
        }, 500);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newUser(l_roles) {
        return {
            children: [],
            email: "",
            is_active: false,
            is_dummy: false,
            meta: {
                title: "",
                first_name: "",
                last_name: "",
                phone: "",
                address: "",
                address2: "",
                vat: "",
                city: "",
                zip: "",
            },
            company_id: "",
            password: "",
            role: {},
            role_id: userRole.access.manage_all_users ? "" : l_roles.length > 0 ? l_roles[0]._id : "",
        }
    }

    function capitalize(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.value;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        let cu = Object.assign({}, currentUser)

        if (subfield === undefined) {
            cu[field] = val;
        } else {
            cu[field][subfield] = val;
        }
        setCurrentUser(cu);
    }

    function handleChangeSwitch(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.checked;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        let cu = Object.assign({}, currentUser)

        if (subfield === undefined) {
            cu[field] = val;
        } else {
            cu[field][subfield] = val;
        }
        setCurrentUser(cu);
    }

    function saveUser() {
        if (isNew) {
            const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user/register";
            return axios.post(ENDPOINT, currentUser, {
                withCredentials: true,
            })
                .then((res) => {
                    setIsNew(false);
                    if (currentUser.company_id.length > 0) {
                        const ASSIGN_ENDPOINT = process.env.REACT_APP_API_URL + "secure/company/assign";

                        axios.patch(ASSIGN_ENDPOINT, {
                            user_id: res.data.id,
                            company_id: currentUser.company_id,
                        }, {
                            withCredentials: true,
                        })
                    }
                });
        }
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
        return axios.put(ENDPOINT, currentUser, {
            withCredentials: true,
        })
            .then((res) => {
                if (currentUser.company_id.length > 0) {
                    const ASSIGN_ENDPOINT = process.env.REACT_APP_API_URL + "secure/company/assign";

                    axios.patch(ASSIGN_ENDPOINT, {
                        user_id: currentUser.id,
                        company_id: currentUser.company_id,
                    }, {
                        withCredentials: true,
                    })
                }

            });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (props.user_id !== undefined && props.user_id.length > 0) {
                    setIsNew(false);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: props.user_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            setDefaultCompany(user.company_id);
                            axios.get(ENDPOINT, {
                                params: { id: my_uid },
                                withCredentials: true,
                            })
                                .then((l_res) => {
                                    const canManageUsers = l_res.data.user.role.access.manage_child_users;
                                    if (canManageUsers) {
                                        loadRolesData();
                                    } else {
                                        setRoles([]);
                                    }
                                })
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setIsNew(true);
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageUsers = l_res.data.user.role.access.manage_child_users;
                            if (canManageUsers) {
                                loadRolesData()
                                    .then((res) => {
                                        setCurrentUser(newUser(res));

                                        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                                        axios.get(ENDPOINT, {
                                            params: { id: userId },
                                            withCredentials: true,
                                        })
                                            .then((res_i) => {
                                                let user = res_i.data.user;
                                                axios.get(process.env.REACT_APP_API_URL + "secure/company", {
                                                    params: { id: user.company_id },
                                                    withCredentials: true,
                                                })
                                                    .then((res_cmp) => {
                                                        let company = res_cmp.data.company;
                                                        setDefaultCompany(company.id);
                                                    })
                                            });
                                    });
                            } else {
                                setRoles([]);
                                setCurrentUser(newUser());
                            }
                        })
                }
                setCompanies(props.companies);
                setIsLoading(false);
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.user")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.user")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew &&
                        <Modal.Title>{t("New User")}</Modal.Title>
                    }
                    {!isLoading && !isNew &&
                        <Modal.Title>{t("Edit User")}</Modal.Title>
                    }
                    {isLoading &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {!isLoading && currentUser &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="secondary">{t("Last login")}: {currentUser.last_login ? moment(currentUser.last_login).format('MMMM Do YYYY, H:mm') : ' - '}</Badge>
                            </Col>
                            <Form.Group as={Col} className="">
                                <Form.Check className="switch-success" id="isActive" name="is_active" type="switch" label={t("Is Active")} defaultChecked={currentUser.is_active} onChange={handleChangeSwitch} />
                            </Form.Group>
                        </Row>
                    }
                    {isLoading &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='primary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    <Row>
                        <Col>
                            {!isLoading && currentUser &&
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("Title")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="meta.title" type="text" placeholder={t("e.g. Manager")} defaultValue={currentUser.meta.title} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>{t("Email")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="email" disabled={props.user_id.length !== 0} type="email" defaultValue={currentUser.email} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>{t("Password")}</Form.Label>
                                            <Form.Control name="password" type="password" placeholder={t("Blank means unchanged")} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridFirstName">
                                            <Form.Label>{t("First Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="meta.first_name" type="text" defaultValue={currentUser.meta.first_name} className={nameClassDisabled(currentUser.meta.first_name)} disabled={isNameDisabled(currentUser.meta.first_name)} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridLastName">
                                            <Form.Label>{t("Last Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="meta.last_name" type="text" defaultValue={currentUser.meta.last_name} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPhone">
                                            <Form.Label>{t("Phone")}</Form.Label>
                                            <Form.Control name="meta.phone" defaultValue={currentUser.meta.phone} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridAddress">
                                            <Form.Label>{("Address")}</Form.Label>
                                            <Form.Control name="meta.address" defaultValue={currentUser.meta.address} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridAddress2">
                                            <Form.Label>{t("Address 2")}</Form.Label>
                                            <Form.Control name="meta.address2" defaultValue={currentUser.meta.address2} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("VAT")}</Form.Label>
                                            <Form.Control name="meta.vat" type="text" defaultValue={currentUser.meta.vat} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label>{t("City")}</Form.Label>
                                            <Form.Control name="meta.city" defaultValue={currentUser.meta.city} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridZip">
                                            <Form.Label>{t("Zip")}</Form.Label>
                                            <Form.Control
                                                name="meta.zip"
                                                defaultValue={currentUser.meta.zip} onChange={handleChangeField}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formRoles">
                                            <Form.Label>{t("Role")}</Form.Label>
                                            <Form.Select name="role_id" value={defaultRole} onChange={handleChangeField} disabled={isNameDisabled(currentUser.meta.first_name) || !userRole.access.manage_all_users}>
                                                <option value={""}>{t("None")}</option>
                                                {!userRole.access.manage_all_users &&
                                                    roles.filter((item) => item._id === currentUser.role_id).map((role) => {
                                                        return (<option value={role._id} key={role._id} >
                                                            {capitalize(role.name)}
                                                        </option>);
                                                    })
                                                }
                                                {userRole.access.manage_all_users &&
                                                    roles.map((role) => {
                                                        return (<option value={role._id} key={role._id} >
                                                            {capitalize(role.name)}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formCompany">
                                            <Form.Label>{t("Company")}</Form.Label>
                                            <Form.Select name="company_id" value={userRole.access.manage_all_users ? currentUser.company_id : defaultCompany} onChange={handleChangeField} disabled={isNameDisabled(currentUser.meta.first_name) || !userRole.access.manage_all_users} >
                                                <option value={""}>{t("None")}</option>
                                                {userRole.access.manage_all_users &&
                                                    companies.filter((c) => c.name.toLowerCase() !== "system").map((cmp) => {
                                                        return (<option value={cmp._id} key={cmp._id}>
                                                            {capitalize(cmp.name)}
                                                        </option>);
                                                    })
                                                }
                                                {!userRole.access.manage_all_users &&
                                                    companies.filter((c) => c._id === defaultCompany).map((cmp) => {
                                                        return (<option value={cmp._id} key={cmp._id}>
                                                            {capitalize(cmp.name)}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>

                                        {userRole.access.manage_all_users &&
                                            <Form.Group as={Col} className="">
                                                <Form.Label className="d-flex justify-content-center" htmlFor="isDummy">{t("Dummy User")}</Form.Label>
                                                <Form.Check className="d-flex justify-content-center" id="isDummy" name="is_dummy" type="switch" label="" defaultChecked={currentUser.is_dummy} onChange={handleChangeSwitch} />
                                            </Form.Group>
                                        }
                                    </Row>
                                </Form>
                            }
                            {!currentUser &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AccountEdit;
