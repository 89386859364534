import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

const SUPPORTED_TYPES = [
    {
        type: "csv",
        name: "CSV"
    },
];

const GRANULARITY_OPTIONS = [
    {
        name: "Seconds",
        value: "seconds",
    },
    {
        name: "Minutes",
        value: "minutes",
    },
    {
        name: "Hours",
        value: "hours",
    },
]

const TIME_FORMATS = [
    {
        name: "2006-01-02 15:04:05",
        type: "2006-01-02 15:04:05",
    },
    {
        name: "Timestamp (milliseconds)",
        type: "milliseconds",
    },
    {
        name: "Timestamp (microseconds)",
        type: "microseconds",
    },
]

function DatasourceEditCSV(props) {
    const { checkLoggedIn } = useContext(AuthContext);

    const [currentType, setCurrentType] = useState("csv");
    const [companies, setCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState("");

    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [currentDatasource, setCurrentDatasource] = useState({ config: {} });
    const [isNew, setIsNew] = useState(false);
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();

    function loadCompanies() {
        let LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/companies";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCompanies(res.data.companies);
                if (res.data.companies.length > 0) {
                    setCurrentCompany(res.data.companies[0]._id);
                }
            }
        });
    }

    function loadDatasourceData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
        return axios.get(LIST_ENDPOINT, {
            params: { id: props.datasource_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                return res.data.datasource;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        setSaving(true);
        saveDatasource()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn().then(() => {
                    props.reLoad();
                })
            })
            .catch((e) => {
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setDatasourceId("");
        }, 500);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newDatasource() {
        return {
            name: "",
            type: "csv",
            company_id: "",
            filename: "",
            config: {
                columns: [],
                timefield: "",
                timeformat: "unix_micro",
                metafield: "",
                values: ['time'],
                granularity: "seconds",
                lifespan: 0,
            },
        }
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.value;

        if (e.target.type === "file") {
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append("file", file);

            const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
            setUploading(true);
            axios.post(ENDPOINT, formData, {
                withCredentials: true,
            })
                .then((response) => {
                    if (response && response.data && !response.data.error) {
                        currentDatasource.filename = response.data.filename;
                        currentDatasource.config["columns"] = response.data.columns;
                        setCurrentDatasource(Object.assign({}, currentDatasource));
                    }
                    setUploading(false);
                })
                .catch((error) => {
                    console.error(error);
                });

        } else if (e.target.type == "select-multiple") {
            var selected = [...e.target.options].filter((o) => o.selected).map((o) => o.value);
            currentDatasource['config']['values'] = selected;
            setCurrentDatasource(Object.assign({}, currentDatasource));
        } else {
            if (field.includes('config')) {
                subfield = field.replace("config.", "");
                field = 'config';
            }

            if (subfield === undefined) {
                currentDatasource[field] = val;
            } else {
                currentDatasource[field][subfield] = val;
            }
        }

        setCurrentDatasource(Object.assign({}, currentDatasource));
    }

    function handleChangeSwitch(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.checked;

        if (field.includes('config')) {
            subfield = field.replace("config.", "");
            field = 'config';
        }

        if (subfield === undefined) {
            currentUser[field] = val;
        } else {
            currentUser[field][subfield] = val;
        }
    }

    function saveDatasource() {
        currentDatasource.company_id = currentCompany;
        currentDatasource.type = currentType;
        setCurrentDatasource(Object.assign({}, currentDatasource));

        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
        if (isNew) {
            return axios.put(ENDPOINT, currentDatasource, {
                withCredentials: true,
            });
        }
        return axios.patch(ENDPOINT, currentDatasource, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (props.datasource_id !== undefined && props.datasource_id.length > 0) {
                    setIsNew(false);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: props.user_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            axios.get(ENDPOINT, {
                                params: { id: my_uid },
                                withCredentials: true,
                            })
                                .then((l_res) => {
                                    // FIXME: Can Manage Datasources
                                    const canManageDatasources = l_res.data.user.role.access.manage_child_users;
                                    if (canManageDatasources) {
                                        // FIXME: Make it faster
                                        loadCompanies()
                                            .then(() => {
                                                    loadDatasourceData()
                                                    .then((ds) => {
                                                        setCurrentDatasource(ds);
                                                        setIsLoading(false)
                                                    })
                                                }
                                            )
                                    }
                                })
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setIsNew(true);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageDatasources = l_res.data.user.role.access.manage_child_users;
                            if (canManageDatasources) {
                                setCurrentDatasource(newDatasource());
                                loadCompanies().then(() => setIsLoading(false));
                            } else {
                                setCurrentDatasource(newDatasource());
                                setIsLoading(false)
                            }
                        })
                }
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.datasource")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.datasource")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew &&
                        <Modal.Title>{t("New Datasource")}</Modal.Title>
                    }
                    {!isLoading && !isNew &&
                        <Modal.Title>{t("Edit Datasource")}</Modal.Title>
                    }
                    {isLoading &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage.length > 0 &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="danger">{errorMessage}</Badge>
                            </Col>
                        </Row>

                    }
                    {!isLoading && currentDatasource &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="secondary">{t("Created At")}: {currentDatasource.created_at && !isNew ? moment(currentDatasource.created_at).format('MMMM Do YYYY, H:mm') : ' - '}</Badge>
                            </Col>
                        </Row>
                    }
                    {isLoading &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='primary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    {currentDatasource && 
                        <Row>
                            <Col>
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="name" type="text" defaultValue={currentDatasource.name} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formType">
                                            <Form.Label>{t("Type")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="type" value={currentType} onChange={(e) => setCurrentType(e.target.value)} >
                                                {
                                                    SUPPORTED_TYPES.map((t) => {
                                                        return (<option value={t.type} key={t.type}>
                                                            {t.name}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formCompany">
                                            <Form.Label>{t("Company")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="company_id" value={currentCompany} onChange={(e) => setCurrentCompany(e.target.value)} >
                                                {
                                                    companies.filter((c) => c.name.toLowerCase() !== "system").map((cmp) => {
                                                        return (<option value={cmp._id} key={cmp._id}>
                                                            {cmp.name}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>


                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridFile">
                                            <Form.Label>
                                                {t("Data File")} <span className="text-danger">*</span>
                                                {uploading &&
                                                    <Spinner animation="border" role="status" size='sm' className='mx-4'>
                                                        <span className="visually-hidden">{t("Saving")}...</span>
                                                    </Spinner>
                                                }
                                            </Form.Label>
                                            <Form.Control name="filename" type="file" accept="text/csv, .zip" defaultValue={currentDatasource.filename} onChange={handleChangeField} />
                                            {!isNew &&
                                                <small>
                                                    <Badge bg="secondary">{currentDatasource.filename}</Badge>
                                                </small>
                                            }
                                        </Form.Group>
                                    </Row>

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridTimeField">
                                                <Form.Label>{t("Time Field")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Select name="config.timefield" value={currentDatasource.config.timefield} onChange={handleChangeField} >
                                                    <option></option>
                                                    {
                                                        currentDatasource.config.columns.filter((o) => o[1] !== currentDatasource.config.metafield).map((col) => {
                                                            return (<option value={col[1]} key={col[0]}>
                                                                {col[1]}
                                                            </option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    }

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridTimeFormat">
                                                <Form.Label>{t("Time Format")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Select name="config.timeformat" value={currentDatasource.config.timeformat} onChange={handleChangeField} required >
                                                    <option></option>
                                                    {
                                                        TIME_FORMATS.map((tf) => {
                                                            return (<option value={tf.type} key={tf.type}>
                                                                {tf.name}
                                                            </option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    }

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridGranularity">
                                                <Form.Label>{t("Granularity")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Select name="config.granularity" value={currentDatasource.config.granularity} onChange={handleChangeField} >
                                                    {
                                                        GRANULARITY_OPTIONS.map((go) => {
                                                            return (<option value={go.value} key={go.value}>
                                                                {go.name}
                                                            </option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    }

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridMetaField">
                                                <Form.Label>{t("Asset Field")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Select name="config.metafield" value={currentDatasource.config.metafield} onChange={handleChangeField} >
                                                    <option></option>
                                                    {
                                                        currentDatasource.config.columns.filter((o) => o[1] !== currentDatasource.config.timefield).map((col) => {
                                                            return (<option value={col[1]} key={col[0]}>
                                                                {col[1]}
                                                            </option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    }

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridLifespan">
                                                <Form.Label>{t("Data Lifespan")} ({t("Optional")}, {t("seconds")})</Form.Label>
                                                <Form.Control name="config.lifespan" type="number" min={0} defaultValue={currentDatasource.config.lifespan} onChange={handleChangeField} />
                                            </Form.Group>
                                        </Row>
                                    }

                                    {currentDatasource.config.columns &&
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridTimeValues">
                                                <Form.Label>{t("Values")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Select name="config.values" multiple value={currentDatasource.config.values} onChange={handleChangeField} >
                                                    {
                                                        currentDatasource.config.columns.filter((o) => o[1] !== currentDatasource.config.metafield && o[1] !== currentDatasource.config.timefield).map((col) => {
                                                            return (<option value={col[1]} key={col[0]}>
                                                                {col[1]}
                                                            </option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    }

                                </Form>
                            </Col>
                        </Row>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DatasourceEditCSV;