import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import AuthContext from '../../helpers/AuthContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function ConversationEdit(props) {
    const [datasources, setDatasources] = useState([]);

    function handleClose() {
        props.handleShow(false);
        props.setConversationId("");
    }

    const [saving, setSaving] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [typedTitle, setTypedTitle] = useState(false)

    const { t } = useTranslation();

    function loadListDatasources() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasources";

        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setDatasources(res.data.datasources);
            }
        });
    }

    function handleSave() {
        setSaving(true);
        setConfirming(true);
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }

    function handleOk() {
        setConfirming(false);

        saveConversation()
            .then((res) => {
                setSaving(false);
                props.reLoad().then(() => props.setConversationId(res));
                handleClose();
            })
    }

    function handleRename(e) {
        setTypedTitle(true);
        currentConversation.title = e.target.value;
        setCurrentConversation(Object.assign({}, currentConversation));
    }

    function handleChangeDatasource(e) {
        currentConversation.datasource_id = e.target.value;
        if (e.target.value.length === 0) {
            currentConversation.title = "New Conversation";
        } else if (!typedTitle) {
            let ds = datasources.filter((item) => item._id === e.target.value)[0];
            currentConversation.title = `Conversation with ${ds.name}`;
        }
        setCurrentConversation(Object.assign({}, currentConversation));
    }

    function newConversation() {
        return {
            title: "New Conversation",
            datasource_id: "",
        }
    }

    const [currentConversation, setCurrentConversation] = useState(null);

    const { userId, checkLoggedIn } = useContext(AuthContext);

    function saveConversation() {
        if (isNew) {
            const ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversation";
            return axios.post(ENDPOINT, currentConversation, {
                withCredentials: true,
            })
                .then((res) => {
                    setIsNew(false);
                    props.setConversationId(res.data.id);
                    return res.data.id;
                });
        }
        // TODO: For later - edit implementation
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversation";
        return axios.put(ENDPOINT, currentConversation, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (res) {
                if (props.conversation_id !== undefined && props.conversation_id.length > 0) {
                    // TODO: For later - edit implementation
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversation";
                    axios.get(ENDPOINT, {
                        params: { id: props.conversation_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let conversation = res.data.conversation;
                            loadListDatasources().then(() => {
                                setCurrentConversation(conversation);
                            })
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentConversation(null)
                            }
                        })
                } else {
                    loadListDatasources().then(() => {
                        setIsNew(true)
                        setCurrentConversation(newConversation())
                    });
                }

            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.conversation")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.conversation")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Edit Conversation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {currentConversation &&
                                <Form
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("Conversation Title")}</Form.Label>
                                            <Form.Control name="title" type="text" value={currentConversation.title} onChange={handleRename} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formDatasource">
                                            <Form.Label>{t("Datasource")}</Form.Label>
                                            <Form.Select name="datasource_id" value={currentConversation.datasource_id} onChange={handleChangeDatasource} >
                                                <option value={""}>{t("None")}</option>
                                                {
                                                    datasources.map((ds) => {
                                                        return (<option value={ds._id} key={ds._id} >
                                                            {ds.name}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                </Form>
                            }
                            {!currentConversation &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConversationEdit;
