import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

import { LineChart } from '@mui/x-charts/LineChart';
import Table from 'react-bootstrap/Table';

import React, { useEffect, useContext, useState, useRef } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import AuthContext from '../../helpers/AuthContext';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from "axios";
import { PlusCircle, Send, Trash, Trash2 } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ConversationEdit from "./edit_conversation";
import ConfirmMessage from "./confirm";
import Spinner from 'react-bootstrap/Spinner';

function Assistant() {
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [conversations, setConversations] = useState([]);
    const [activeConversation, setActiveConversation] = useState("");
    const [history, setHistory] = useState({});
    const [currentMessage, setCurrentMessage] = useState("");
    const [isAILoading, setIsAILoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const { t } = useTranslation();

    let stream = null;

    let convId = useRef();
    convId.current = activeConversation;

    let historyRef = useRef();
    historyRef.current = history;

    function sendUserMessage(msg) {
        const payload = {
            "message": msg,
            "conversation_id": activeConversation,
        };
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/chat";
        return axios.post(INFO_ENDPOINT, payload, {
            withCredentials: true,
        });
    }

    function loadAssistantConversations() {
        setIsLoading(true);
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversations";
        return axios.get(INFO_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error) {
                setConversations(res.data.conversations);
            }
            setIsLoading(false);
        });
    }

    function loadHistory(conversation_id) {
        setHistory({});
        const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/history";
        return axios.get(INFO_ENDPOINT, {
            params: { conversation_id: conversation_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data && !res.data.error && res.data.history) {
                setHistory(Object.assign({}, res.data.history));
                setActiveConversation(conversation_id);
                // subscribeToSocket();
                setTimeout(() => {
                    let messages = document.getElementsByClassName("message");
                    if (messages.length > 0) {
                        let message = messages[messages.length - 1];
                        message.scrollIntoView({ behavior: "instant" })
                    }
                }, 100);
                return res.data.history;
            }
            return [];
        });
    }

    function newConversation() {
        setActiveConversation("");
        setShowEdit(true);
    }

    function handleDelete(id) {
        setToDelete(id);
        setShowDelete(true);
    }

    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/assistant/conversation";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadAssistantConversations();
            setActiveConversation("");
            setIsAILoading(false);
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function handleLoading() {
        setIsAILoading(true);
        let loaders = document.getElementsByClassName("ai-loading");
        if (loaders.length > 0) {
            let loader = loaders[0];
            loader.scrollIntoView();
        }
    }

    function handleReLoading() {
        loadHistory(convId.current);
    }

    function checkForAnswerFromAI() {
        setTimeout(() => {
            let msgs = historyRef.current.messages;
            if (isAILoading && msgs.length > 0) {
                let lastMsg = msgs[msgs.length - 1];
                if (lastMsg.who === "assistant") {
                    setIsAILoading(false);
                } else {
                    loadHistory(convId.current).then((newHistory) => {
                        let l = newHistory.messages.length;
                        if (l > 0) {
                            let lastMsg = newHistory.messages[l - 1];
                            if (lastMsg.who === "assistant") {
                                setIsAILoading(false);
                            } else {
                                checkForAnswerFromAI();
                            }
                        }
                    });
                }
            }
        }, 1000);
    }

    function handleMessage() {
        loadHistory(convId.current).then((newHistory) => {
            let l = newHistory.messages.length;
            if (l > 0) {
                let lastMsg = newHistory.messages[l - 1];
                if (lastMsg.who === "assistant") setIsAILoading(false);
            }
        });
        if (isAILoading) {
            checkForAnswerFromAI();
        }
    }

    function onSocketConnect() {
    }

    function onSocketDisonnect() {
    }

    function onSocketError(e) {
        console.error("Error: " + e);
    }

    function subscribeToSocket(uid) {
        if (stream !== null) {
            stream.removeEventListener("reload", handleReLoading);
            stream.removeEventListener("loading", handleLoading);
            stream.removeEventListener("message", handleMessage);

            stream.removeEventListener("open", onSocketConnect);
            stream.removeEventListener("error", onSocketError);
            stream.removeEventListener("close", onSocketDisonnect);

            stream.close();
            stream = null;
        }

        let roomID = userId;
        if (!userId) {
            roomID = uid;
        }

        const SOCKET_ENDPOINT = process.env.REACT_APP_API_URL.replace("http", "ws") + "stream/" + roomID;

        stream = new WebSocket(SOCKET_ENDPOINT);
        stream.addEventListener("reload", handleReLoading);
        stream.addEventListener("loading", handleLoading);
        stream.addEventListener("message", handleMessage);

        stream.addEventListener("open", onSocketConnect);
        stream.addEventListener("error", onSocketError);
        stream.addEventListener("close", onSocketDisonnect);

        window.addEventListener("unload", function () {
            if (stream.readyState === WebSocket.OPEN) stream.close();
        });
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                subscribeToSocket(res);
                loadAssistantConversations();
            }
        });
        return () => {
            if (stream !== null && stream.readyState === WebSocket.OPEN) {
                stream.close();
                stream = null;
            }
        };
    }, [])

    function sendMessage() {
        if (currentMessage.length === 0) return;
        var newMessage = {
            who: "user",
            when: moment.utc(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            what: {
                message: currentMessage,
            }
        };
        let l_history = history.messages ? [...history.messages, newMessage] : [newMessage];
        history.messages = l_history;
        setHistory(Object.assign({}, history));
        sendUserMessage(currentMessage).then(() => {
            setCurrentMessage("");
            setIsAILoading(true);
        })
    }

    return (
        <Container className="App">
            <Navbar></Navbar>
            <Container className="p-0 p-md-4 container-fluid mb-5">
                <Row>
                    <Col>
                        <Card border="dark shadow">
                            <Card.Header>{t("AI Assistant")}</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={4}>
                                        <Card className="h100 mb-3">
                                            <Card.Body className="h-100">
                                                <Card.Title className="d-flex justify-content-between">
                                                    <span className="my-2">{t("Conversations")}</span>
                                                    {isLoading &&
                                                        <Spinner animation="border" role="status" size='sm' variant='primary' className="m-2 mt-3">
                                                            <span className="visually-hidden">{t("Loading")}...</span>
                                                        </Spinner>
                                                    }
                                                    {!isLoading &&
                                                        <Button variant="outline-primary" onClick={newConversation}>
                                                            <PlusCircle />
                                                        </Button>
                                                    }
                                                </Card.Title>
                                                {conversations.length > 0 &&
                                                    <ListGroup>
                                                        {
                                                            conversations.map((c) => {
                                                                return <ListGroup.Item action onClick={() => loadHistory(c._id)} key={c._id} active={c._id === activeConversation}>
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <h5 className="mb-1">{c.title}</h5>
                                                                    </div>
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <small className="mb-1" title={moment.parseZone(c.created_at).format("YYYY-MM-DD@HH:mm:ss")}>{moment.parseZone(c.created_at).fromNow()}</small>
                                                                        <Button variant="danger" size="sm"
                                                                            style={{ paddingTop: "0.15rem", paddingBottom: "0.15rem", paddingLeft: "0.4rem", paddingRight: "0.4rem", fontSize: "0.6rem" }}
                                                                            onClick={() => handleDelete(c._id)}
                                                                        >
                                                                            <Trash />
                                                                        </Button>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            })
                                                        }
                                                    </ListGroup>
                                                }
                                                {conversations.length == 0 &&
                                                    <span className="text-secondary">{t("No Conversations")}</span>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={8}>
                                        <Container className="h-100">
                                            <Card className="h-75 mb-2 overflow-auto" style={{ maxHeight: "600px" }}>
                                                <Card.Body className="p-0 mh-100" style={{ minHeight: "500px", }}>
                                                    {activeConversation.length > 0 && history.messages && history.messages.length > 0 &&
                                                        history.messages.map((chat, idx) => {
                                                            let userStyle = chat.who === "user" ? { borderRight: "2px solid #f20040" } : { borderLeft: "2px solid #4fb2eb" };
                                                            let msg = chat.what.message;
                                                            try {
                                                                msg = JSON.parse(msg);
                                                            } catch (e) {
                                                                msg = msg;
                                                            }
                                                            return <div className="w-100 border-top border-bottom px-2 py-1 mt-1 message" style={userStyle} key={chat.when}>
                                                                {chat.who === "user" &&
                                                                    <div className="d-flex w-100 justify-content-between" title={moment.parseZone(chat.when).format("YYYY-MM-DD@HH:mm:ss")}>
                                                                        <small className="text-secondary mx-2">
                                                                            {moment.parseZone(chat.when).fromNow()}
                                                                        </small>
                                                                        <span>
                                                                            {chat.who}
                                                                            <Image className="my-1 mx-2" src="/icon.png" width={16} roundedCircle />
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {chat.who === "assistant" &&
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <span>
                                                                            <Image className="my-1 mx-2" src="/assistant_icon.png" width={16} roundedCircle />
                                                                            <small>{t("Assistant")}</small>
                                                                        </span>
                                                                        <small className="text-secondary mx-2" title={moment.parseZone(chat.when).format("YYYY-MM-DD@HH:mm:ss")}>
                                                                            {moment.parseZone(chat.when).fromNow()}
                                                                        </small>
                                                                    </div>
                                                                }
                                                                <div className="d-flex w-100 justify-content-start flex-column">
                                                                    {chat.who === "assistant" && chat.what.message && msg && msg.response && msg.response.includes("Invalid API Key") &&
                                                                        <span className="text-danger">{msg.response}</span>
                                                                    }
                                                                    {chat.who === "assistant" && msg && !msg.response &&
                                                                        <span className="text-danger">{msg.detail}</span>
                                                                    }
                                                                    {chat.who === "assistant" && chat.what.message && msg && msg.response && !msg.response.includes("Invalid API Key") &&
                                                                        <div dangerouslySetInnerHTML={{ __html: msg.response }}></div>
                                                                    }
                                                                    {chat.who === "user" && msg &&
                                                                        msg
                                                                    }
                                                                    {chat.what.confirm &&
                                                                        <ConfirmMessage id={activeConversation} idx={idx} what={chat.what} reLoad={loadHistory} />
                                                                    }
                                                                    {chat.what.plot &&
                                                                        <div className="d-flex w-100 justify-content-center">
                                                                            <LineChart className='w-100 justify-content-center'
                                                                                xAxis={[
                                                                                    {
                                                                                        data: chat.what.plot.data.map((item, idx) => moment.parseZone(item.timestamp)),
                                                                                        scaleType: "utc",
                                                                                        tickNumber: 3,
                                                                                        valueFormatter: (v, c) => {
                                                                                            return moment.parseZone(v).format("YYYY-MM-DD@HH:mm:ss.SS");
                                                                                        }
                                                                                    },
                                                                                ]}
                                                                                series={[
                                                                                    {
                                                                                        curve: "linear",
                                                                                        showMark: false,
                                                                                        data: chat.what.plot.data.map((item) => parseFloat(item.value)),
                                                                                        valueFormatter: (v) => {
                                                                                            return `${v}`;
                                                                                        }
                                                                                    },
                                                                                ]}
                                                                                width={300}
                                                                                height={200}
                                                                            />
                                                                        </div>

                                                                    }
                                                                    {chat.what.table &&
                                                                        <div className="d-flex w-100 justify-content-center">
                                                                            <Table striped bordered hover size="sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {chat.what.table.labels.map((th) => {
                                                                                            return <th key={th}>{th}</th>
                                                                                        })}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        chat.what.table.data.map((row) => {
                                                                                            return <tr key={row}>
                                                                                                {row.map((col) => {
                                                                                                    return <td key={col}>{col}</td>
                                                                                                })}
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                    {isAILoading &&
                                                        <div className="w-100 border-top border-bottom px-2 py-1 mt-1 ai-loading" style={{ borderLeft: "2px solid #4fb2eb" }}>
                                                            <div className="d-flex w-100 justify-content-start">
                                                                <span>
                                                                    <Image className="my-1 mx-2" src="/assistant_icon.png" width={16} roundedCircle />
                                                                    <small>{t("Assistant")}</small>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex w-100 justify-content-center">
                                                                <span className="loader"></span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        activeConversation.length === 0 &&
                                                        <h3 className="mt-4 text-secondary w-100 text-center">{t("Please select a Conversation")}</h3>
                                                    }
                                                </Card.Body>
                                            </Card>
                                            <Card className="p-2 mt-2">
                                                <InputGroup className="">
                                                    <Form.Control
                                                        aria-label="Chat Input"
                                                        aria-describedby="chat-input"
                                                        onChange={(e) => setCurrentMessage(e.target.value)}
                                                        onKeyUp={(e) => { if (e.key === "Enter") sendMessage(); }}
                                                        value={currentMessage}
                                                        disabled={activeConversation.length === 0}
                                                    />
                                                    <Button
                                                        variant="outline-secondary"
                                                        id="send-msg"
                                                        onClick={sendMessage}
                                                        disabled={currentMessage.length === 0 || activeConversation.length === 0}
                                                    >
                                                        <Send />
                                                    </Button>
                                                </InputGroup>
                                            </Card>
                                        </Container>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {showEdit &&
                <ConversationEdit show={showEdit} handleShow={setShowEdit} conversation_id={activeConversation} setConversationId={setActiveConversation} reLoad={loadAssistantConversations} />
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.conversation")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
            <Footer />
        </Container>
    );
}

export default Assistant;

